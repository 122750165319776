import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Form, FormControl, Modal, Image, Spinner, OverlayTrigger, Tooltip, ButtonGroup } from 'react-bootstrap';
import { FetchService } from '../services/FetchService';
import {getCurrentUser} from 'aws-amplify/auth';
import * as XLSX from 'xlsx';

interface InventoryItem {
    sku: string;
    uretimTarihi: string;
    isim: string;
    quantity: number;
    durum: string;
    kategori: string;
    kalinlik: string;
    uzunluk: string;
    materyal: string;
    renk: string;
    kilogram: number;
    makineNo: string;
    urunEkleyen: string;
}

const fetchService = new FetchService("https://6pecywccp1.execute-api.eu-central-1.amazonaws.com");

const Inventory: React.FC = () => {
    const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<InventoryItem | null>(null);
    const [newItem, setNewItem] = useState<Partial<InventoryItem>>({});
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [labelUrl, setLabelUrl] = useState<string | null>(null);
    const [showLabelModal, setShowLabelModal] = useState(false);
    const [validationError, setValidationError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [showSellModal, setShowSellModal] = useState(false);
    const [isSelling, setIsSelling] = useState<boolean>(false);
    const [customerName, setCustomerName] = useState('');
    const [shipmentNo, setShipmentNo] = useState('');
    const [shipmentDate, setShipmentDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1); 
    const itemsPerPage = 10; 
    

    const colorMap: { [key: string]: string } = {
        "Benekli": "#f0e68c",
        "Beyaz": "#ffffff",
        "Siyah": "#000000",
        "Sarı": "#ffff00",
        "Kırmızı": "#ff0000",
        "Mavi": "#0000ff",
        "Yeşil": "#008000",
        "Krem": "#fffdd0",
        "Gri": "#808080",
        "Açık Gri": "#d3d3d3",
        "Haki": "#808000",
        "Lacivert": "#000080",
        "Turuncu": "#ffa500",
    };

    const ColorBox: React.FC<{ color: string }> = ({ color }) => {
        const backgroundColor = colorMap[color] || color.toLowerCase();
        return (
            <div 
                style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: backgroundColor,
                    display: 'inline-block',
                    marginRight: '8px',
                    borderRadius: '3px',
                    border: '1px solid #000'
                }}
            ></div>
        );
    };

    const exportToExcel = () => {


        const reorderedItems = inventoryItems.map(item => ({
            "İsim": item.isim, 
            "Kategori": item.kategori, 
            "Kalınlık": item.kalinlik, 
            "Uzunluk": item.uzunluk, 
            "Kilogram": item.kilogram,
            "Renk": item.renk, 
            "Materyal": item.materyal, 
            "Ürünü Ekleyen": item.urunEkleyen, 
            "Üretim Tarihi": item.uretimTarihi, 
            "SKU": item.sku, 
            "Durum": item.durum, 
            "Makine No": item.makineNo
        }));

        const worksheet = XLSX.utils.json_to_sheet(reorderedItems);
        
        const workbook = XLSX.utils.book_new();
    
        XLSX.utils.book_append_sheet(workbook, worksheet, "Envanter Raporu");
    
        // gmt+3 for Turkey
        const date = new Date();
        date.setHours(date.getHours() + 3);
        XLSX.writeFile(workbook, `Envanter_Rapor_Molhalat_${date.toISOString()}.xlsx`);
    };
    
    // Ref to the label image for printing
    const labelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                setLoading(true);    
                const data = await fetchService.getItems('items');

            // Sort items by uretimTarihi in descending order
                const sortedData = data.sort((a: { uretimTarihi: string | number | Date; }, b: { uretimTarihi: string | number | Date; }) => new Date(b.uretimTarihi).getTime() - new Date(a.uretimTarihi).getTime());
                
                setInventoryItems(sortedData);

            } catch (error) {
                console.error('Failed to fetch items:', error);
            } finally{
                setLoading(false);
            }
        };

        fetchItems();
    }, []);

    const handleEdit = (item: InventoryItem) => {
        setCurrentItem(item);
        setShowModal(true);
    };

    const handleDelete = async (sku: string) => {
        try {
            setLoading(true);

            await fetchService.deleteItem('items', sku);
            setInventoryItems(inventoryItems.filter(item => item.sku !== sku));
        } catch (error) {
            console.error('Failed to delete item:', error);
        } finally {
            setLoading(false);
        }
    };
    // For currentItem
    useEffect(() => {
        if (currentItem) {
            const generatedName = generateProductName(currentItem.renk, currentItem.kalinlik, currentItem.uzunluk, currentItem.kategori);
            if (currentItem.isim !== generatedName) {
                setCurrentItem({ ...currentItem, isim: generatedName });
            }
        }
    }, [currentItem?.renk, currentItem?.kalinlik, currentItem?.uzunluk, currentItem?.kategori]);

    // For newItem
    useEffect(() => {
        if (!currentItem) {
            const generatedName = generateProductName(newItem.renk || '', newItem.kalinlik || '', newItem.uzunluk || '', newItem.kategori || '');
            if (newItem.isim !== generatedName) {
                setNewItem({ ...newItem, isim: generatedName });
            }
        }
    }, [newItem.renk, newItem.kalinlik, newItem.uzunluk, newItem.kategori]);

    const generateProductName = (color: string, thickness: string, length: string, category: string) => {
        if(category === "8Kollu Yüzen Halat") return "Fibre rope ISO 1346 – L – 60 – PP3 protected (UV)";
        if (!color || !thickness || !length || !category) return "";
        return `${thickness}mm - ${category} - ${length}m - ${color}`;
    };    

    const handleSell = async () => {
        if (!currentItem) {
            setValidationError("Satılacak ürün seçilmedi.");
            return;
        }
    
        if (!customerName || !shipmentNo || !shipmentDate) {
            setValidationError("Lütfen müşteri adı, sipariş numarası ve sevk tarihini girin.");
            return;
        }
    
        try {
            setIsSelling(true);
    
            // Prepare the item for sale
            const soldItem = {
                ...currentItem,
                musteriAdi: customerName,
                sevkNo: shipmentNo,
                sevkTarih: shipmentDate,
                // soldDate: new Date().toISOString(), 
            };
    
            console.log('Sold Item:', soldItem);
            // Call the API to move the item to the sold_items table
            await fetchService.sellItem('sell/item', soldItem);
    
            // Update inventory by removing the sold item
            setInventoryItems(inventoryItems.filter(item => item.sku !== currentItem.sku));
    
            // Clear the sell modal and reset the states
            setShowSellModal(false);
            setCustomerName('');
            setShipmentNo('');
            setShipmentDate('');
            setValidationError(null);
        } catch (error) {
            console.error('Failed to sell item:', error);
            setValidationError('Satış işlemi sırasında hata oluştu.');
        } finally {
            setIsSelling(false);
        }
    };

    const handleSellClick = (item: InventoryItem) => {
        setCurrentItem(item);  // Set the current item to the item being sold
        setShowSellModal(true);  // Open the sell modal
    };
    
    const handleSave = async () => {
        const itemToSave = currentItem || newItem;
    
        if (!itemToSave.kategori) {
            setValidationError("Lütfen kategori alanını seçin.");
            return;
        }
    
        if (!itemToSave.kalinlik) {
            setValidationError("Lütfen kalınlık alanını seçin.");
            return;
        }
    
        if (!itemToSave.uzunluk) {
            setValidationError("Lütfen uzunluk alanını seçin.");
            return;
        }
    
        if (!itemToSave.materyal) {
            setValidationError("Lütfen materyal alanını seçin.");
            return;
        }
    
        if (!itemToSave.renk) {
            setValidationError("Lütfen renk alanını seçin.");
            return;
        }
    
        if (!itemToSave.kilogram || itemToSave.kilogram <= 0) {
            setValidationError("Lütfen geçerli bir kilogram değeri girin.");
            return;
        }
        if(!itemToSave.makineNo){
            setValidationError("Lütfen makine numarasını seçin.");
            return;
        }
    
        try {

            const user = await getCurrentUser();
            const username = user.username;

            setIsSaving(true);
            itemToSave.durum = "Stokta";
            itemToSave.urunEkleyen = username;

    
            if (currentItem) {
                // Update existing item
                await fetchService.updateItem('items', currentItem.sku, currentItem);
            } else {
                // Create new item
                await fetchService.createItem('items', newItem);
            }
    
            setShowModal(false);
            setValidationError(null);
    
            const updatedItems = await fetchService.getItems('items');

            const sortedData = updatedItems.sort((a: { uretimTarihi: string | number | Date; }, b: { uretimTarihi: string | number | Date; }) => new Date(b.uretimTarihi).getTime() - new Date(a.uretimTarihi).getTime());
                
            setInventoryItems(sortedData);
        } catch (error) {
            console.error('Failed to save item:', error);
        } finally{
            setIsSaving(false);
        }
    };
    
    const handleCancel = (setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>) => {
        setIsCancelling(true);
        setTimeout(() => {
            setModalVisibility(false);
            setIsCancelling(false);  
        }, 250); 
    };
    
    const handleAddNewItem = () => {
        setCurrentItem(null);
        setNewItem({});
        setShowModal(true);
    };

    const handleRetrieveLabel = async (sku: string) => {
        try {
            const response = await fetchService.getItem(`labels/${sku}`);
            setLabelUrl(response.label_url);
            setShowLabelModal(true);
        } catch (error) {
            console.error('Failed to retrieve label:', error);
        }
    };

    // Print the label by opening the print window for the label image
    const handlePrint = () => {
        if (labelRef.current) {
            const printContents = labelRef.current.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload();
        }
    };

    const filteredItems = inventoryItems.filter(
        (item) =>
            item.isim.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.sku.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.durum.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    const paginatedItems = filteredItems.slice(
        (currentPage-1) * itemsPerPage,
        currentPage * itemsPerPage
    )

    // Pagination functions
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    //check later /////////////////////////////
    const goToPage = (page:any) => {
        setCurrentPage(page);
    };

    return (
        <div className="container mt-4">
            <h2>Envanter</h2>

            <div className="mb-4">
                <Form className="d-flex">
                    <FormControl
                        type="search"
                        placeholder="Ürün Ara"
                        className="me-2"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Button variant="outline-success">Ara</Button>
                </Form>
            </div>

            <div className="d-flex justify-content-between mt-2 mb-2">
                <Button variant="success" size="lg" onClick={exportToExcel}>Rapor İndir</Button>
                <Button variant="primary" size="lg" onClick={handleAddNewItem}>Ürün Ekle</Button>
            </div>

            {/* Responsive Table */}

            {loading ? (
                <div className="text-center my-4">
                    <Spinner animation="border" role="status">
                        <span className='visually-hidden'>Yükleniyor...</span>
                    </Spinner>
                </div>
            ): (
                <div className="table-responsive">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Ürün adı</th>
                                <th>SKU</th>
                                <th>Durum</th>
                                <th className="d-none d-md-table-cell">Kategori</th>
                                <th className="d-none d-md-table-cell">Kalınlık</th>
                                <th className="d-none d-md-table-cell">Uzunluk</th>
                                <th className="d-none d-lg-table-cell">Materyal</th>
                                <th className="d-none d-lg-table-cell">Renk</th>
                                <th className="d-none d-lg-table-cell">Kilogram</th>
                                <th>Eylemler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedItems.map((item) => (
                                <tr key={item.sku}>
                                    <td>{item.isim}</td>
                                    <td>{item.sku}</td>
                                    <td>{item.durum}</td>
                                    <td className="d-none d-md-table-cell">{item.kategori}</td>
                                    <td className="d-none d-md-table-cell">{item.kalinlik}</td>
                                    <td className="d-none d-md-table-cell">{item.uzunluk}</td>
                                    <td className="d-none d-lg-table-cell">{item.materyal}</td>
                                    <td className="d-none d-lg-table-cell">
                                        <ColorBox color={item.renk} /> {item.renk}
                                    </td>
                                    <td className="d-none d-lg-table-cell">{item.kilogram}</td>
                                    <td>
                                        <Button variant="warning" size="sm" className="me-2" onClick={() => handleEdit(item)}>Düzenle</Button>
                                        <Button variant="info" size="sm" className="me-2" onClick={() => handleRetrieveLabel(item.sku)}>Etiketi Görüntüle</Button>
                                        <Button variant="success" size="sm" className="me-2" onClick={() => handleSellClick(item)}>Sat</Button>
                                        <Button variant="danger" size="sm" onClick={() => handleDelete(item.sku)}>Sil</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            {/* Pagination Buttons under table */}
            <div className="pagination-control d-flex justify-content-center my-3">
                {/* Önceki Sayfa Butonu */}
                <OverlayTrigger placement="top" overlay={<Tooltip>Önceki Sayfa</Tooltip>}>
                    <Button
                        variant="outline-primary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="mx-1"
                    >
                        {"<"}
                    </Button>
                </OverlayTrigger>

                {/* Page Number Buttons */}
                {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    if (
                        pageNumber === 1 ||
                        pageNumber === totalPages ||
                        (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2)
                    ) {
                        return (
                            <Button
                                variant={currentPage === pageNumber ? 'primary' : 'outline-secondary'}
                                key={pageNumber}
                                onClick={() => goToPage(pageNumber)}
                                className={`mx-1 ${currentPage === pageNumber ? 'fw-bold' : ''}`}
                            >
                                {pageNumber}
                            </Button>
                        );
                    } else if (
                        (pageNumber === currentPage - 3 && currentPage > 4) ||
                        (pageNumber === currentPage + 3 && currentPage < totalPages - 3)
                    ) {
                        return <span key={pageNumber} className="mx-2 text-muted">...</span>;
                    } else {
                        return null;
                    }
                })}

                {/* Sonraki Sayfa Butonu */}
                <OverlayTrigger placement="top" overlay={<Tooltip>Sonraki Sayfa</Tooltip>}>
                    <Button
                        variant="outline-primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="mx-1"
                    >
                        {">"}
                    </Button>
                </OverlayTrigger>
            </div>

            <div className="d-flex justify-content-between mt-4">
                <Button variant="success" size="lg" onClick={exportToExcel}>Rapor İndir</Button>
                <Button variant="primary" size="lg" onClick={handleAddNewItem}>Ürün Ekle</Button>
            </div>


            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentItem ? 'Ürünü Düzenle' : 'Yeni Ürün Ekle'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Form Content */}
                    {validationError && (
                        <div className="alert alert-danger">
                            {validationError}
                        </div>
                    )}
                            {/* <Form.Label>Ürün adı <span style={{ color: 'red' }}>*</span></Form.Label> */}  {/* Add asterisk for required fields */}

                    <Form>
                        <Form.Group controlId="formName">
                            <Form.Label>Ürün adı</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ürün adı otomatik oluşturulacak"
                                value={currentItem?.isim || newItem.isim || ''}
                                readOnly
                            />
                        </Form.Group>

                        <Form.Group controlId="formKategori">
                            <Form.Label>Kategori</Form.Label>
                            <Form.Control
                                as="select"
                                value={currentItem?.kategori || newItem.kategori || ''}
                                onChange={(e) =>{ 
                                    setValidationError(null);  // Clear the error message when the user types
                                    currentItem ? setCurrentItem({ ...currentItem, kategori: e.target.value }) : setNewItem({ ...newItem, kategori: e.target.value })
                                }}
                            >
                                <option value="">Kategori Seç</option>
                                <option value="Yelken Halatı">Yelken Halatı</option>
                                <option value="Batan (Tonoz) Halat">Batan (Tonoz) Halat</option>
                                <option value="Statik Halat">Statik Halat</option>
                                <option value="Polyamid Halat">Polyamid Halat</option>
                                <option value="Yüzen Örgülü Halat">Yüzen Örgülü Halat</option>
                                <option value="Yüzen Halat">Yüzen Halat</option>
                                <option value="Usturmaça İpi">Usturmaça İpi</option>
                                <option value="UHMWPE Halat">UHMWPE Halat</option>
                                <option value="Kırcala İpi - Cord">Kırcala İpi - Cord</option>
                                <option value="Parakete İpi">Parakete İpi</option>
                                <option value="Lastik Halat">Lastik Halat</option>
                                <option value="Bağlama Halatı">Bağlama Halatı</option>
                                <option value="8Kollu Batan Halat">8Kollu Batan Halat</option>
                                <option value="8Kollu Yüzen Halat">8Kollu Yüzen Halat</option>
                                <option value="12Kollu Batan Halat">12Kollu Batan Halat</option>
                                <option value="12Kollu Yüzen Halat">12Kollu Yüzen Halat</option>
                                <option value="İpek İskota Halat">İpek İskota Halat</option>
                                <option value="Ekonomik İskota Halat">Ekonomik İskota Halat</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formKalinlik">
                            <Form.Label>Kalınlık</Form.Label>
                            <Form.Control
                                as="select"
                                value={currentItem?.kalinlik || newItem.kalinlik || ''}
                                onChange={(e) => {
                                    setValidationError(null);  // Clear the error message when the user types
                                    currentItem ? setCurrentItem({ ...currentItem, kalinlik: e.target.value }) : setNewItem({ ...newItem, kalinlik: e.target.value })
                                
                                }}
                            >
                                <option value="">Kalınlık Seç</option>
                                {[...Array(30)].map((_, i) => (
                                    <option key={i} value={i * 2 + 2}>{i * 2 + 2}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formUzunluk">
                            <Form.Label>Uzunluk</Form.Label>
                            <Form.Control
                                as="select"
                                value={currentItem?.uzunluk || newItem.uzunluk || ''}
                                onChange={(e) => {
                                    setValidationError(null);  // Clear the error message when the user types
                                    currentItem ? setCurrentItem({ ...currentItem, uzunluk: e.target.value }) : setNewItem({ ...newItem, uzunluk: e.target.value })
                                }}
                            >
                                <option value="">Uzunluk Seç</option>
                                <option value="50">50</option>
                                <option value="60">60</option>
                                <option value="70">70</option>
                                <option value="80">80</option>
                                <option value="90">90</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                                <option value="250">250</option>
                                <option value="300">300</option>
                                <option value="350">350</option>
                                <option value="400">400</option>
                                <option value="450">450</option>
                                <option value="500">500</option>
                                <option value="550">550</option>
                                <option value="600">600</option>
                                <option value="650">650</option>
                                <option value="700">700</option>
                                <option value="750">750</option>
                                <option value="800">800</option>
                                <option value="850">850</option>
                                <option value="900">900</option>
                                <option value="950">950</option>
                                <option value="1000">1000</option>
                                <option value="1050">1050</option>
                                <option value="1100">1100</option>
                                <option value="1150">1150</option>
                                <option value="1200">1200</option>
                                <option value="1250">1250</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formMateryal">
                            <Form.Label>Materyal</Form.Label>
                            <Form.Control
                                as="select"
                                value={currentItem?.materyal || newItem.materyal || ''}
                                onChange={(e) => {
                                    setValidationError(null);  // Clear the error message when the user types
                                    currentItem ? setCurrentItem({ ...currentItem, materyal: e.target.value }) : setNewItem({ ...newItem, materyal: e.target.value })
                                }}
                            >
                                <option value="">Materyal Seç</option>
                                <option value="PES">PES</option>
                                <option value="T.PES">T.PES</option>
                                <option value="T.PES-PES">T.PES-PES</option>
                                <option value="PP">PP</option>
                                <option value="JÜT">JÜT</option>
                                <option value="POL">POL</option>
                                <option value="DYNEEMA">DYNEEMA</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formRenk">
                            <Form.Label>Renk</Form.Label>
                            <Form.Control
                                as="select"
                                value={currentItem?.renk || newItem.renk || ''}
                                onChange={(e) => {
                                    setValidationError(null);  // Clear the error message when the user types
                                    currentItem ? setCurrentItem({ ...currentItem, renk: e.target.value }) : setNewItem({ ...newItem, renk: e.target.value })
                                }}
                            >
                                <option value="">Renk Seç</option>
                                <option value="Benekli">Benekli</option>
                                <option value="Beyaz">Beyaz</option>
                                <option value="Siyah">Siyah</option>
                                <option value="Siyah-Beyaz Kırçıllı">Siyah-Beyaz Kırçıllı</option>
                                <option value="Sarı">Sarı</option>
                                <option value="Kırmızı">Kırmızı</option>
                                <option value="Mavi">Mavi</option>
                                <option value="Yeşil">Yeşil</option>
                                <option value="Krem">Krem</option>
                                <option value="Gri">Gri</option>
                                <option value="Açık Gri">Açık Gri</option>
                                <option value="Haki">Haki</option>
                                <option value="Lacivert">Lacivert</option>
                                <option value="Turuncu">Turuncu</option>
                            </Form.Control>
                        </Form.Group>


                        <Form.Group controlId="formKilogram">
                            <Form.Label>Kilogram</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Kilogram"
                                value={currentItem?.kilogram || newItem.kilogram || ''}
                                onChange={(e) => {
                                    setValidationError(null);  // Clear the error message when the user types
                                    currentItem ? setCurrentItem({ ...currentItem, kilogram: parseFloat(e.target.value) }) : setNewItem({ ...newItem, kilogram: parseFloat(e.target.value) })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formMakineNo">
                            <Form.Label>Makine No</Form.Label>
                            <Form.Control
                                as="select"
                                value={currentItem?.makineNo || newItem.makineNo || ''}
                                onChange={(e) => {
                                    setValidationError(null);  // Clear the error message when the user types
                                    currentItem ? setCurrentItem({ ...currentItem, makineNo: e.target.value }) : setNewItem({ ...newItem, makineNo: e.target.value })
                                }}
                            >
                                <option value="">Makine No Seç</option>
                                <option value="Makine No - 1">Makine No - 1</option>
                                <option value="Makine No - 2">Makine No - 2</option>
                                <option value="Makine No - 3">Makine No - 3</option>
                                <option value="Makine No - 4">Makine No - 4</option>
                                <option value="Makine No - 5">Makine No - 5</option>
                                <option value="Makine No - 6">Makine No - 6</option>
                                <option value="Makine No - 7">Makine No - 7</option>
                                <option value="Makine No - 8">Makine No - 8</option>
                                <option value="Makine No - 9">Makine No - 9</option>
                                <option value="Makine No - 10">Makine No - 10</option>
                                <option value="Makine No - 11">Makine No - 11</option>
                                <option value="Makine No - 12">Makine No - 12</option>
                                <option value="Makine No - 13">Makine No - 13</option>
                                <option value="Makine No - 14">Makine No - 14</option>
                                <option value="Makine No - 15">Makine No - 15</option>
                                <option value="Makine No - 16">Makine No - 16</option>
                                <option value="Makine No - 17">Makine No - 17</option>
                                <option value="Makine No - 18">Makine No - 18</option>
                                <option value="Makine No - 19">Makine No - 19</option>
                                <option value="Makine No - 20">Makine No - 20</option>
                                <option value="Makine No - 21">Makine No - 21</option>
                                
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* Cancel Button */}
                    <Button variant="secondary" onClick={() => handleCancel(setShowModal)} disabled={isCancelling}>
                        {isCancelling ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                            'İptal'
                        )}
                    </Button>

                    {/* Save Button */}
                    <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                        {isSaving ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                            'Kaydet'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Label Modal */}
            <Modal show={showLabelModal} onHide={() => setShowLabelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Etiket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div ref={labelRef}>
                        {labelUrl ? (
                            <Image src={labelUrl} fluid />
                        ) : (
                            <p>Etiket yüklenemedi.</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleCancel(setShowLabelModal)} disabled={isCancelling}>
                        {isCancelling ? (
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            ) : (
                                'İptal'
                            )}
                    </Button>
                    <Button variant="primary" onClick={handlePrint}>
                        Yazdır
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Sell Modal */}
            <Modal show={showSellModal} onHide={() => setShowSellModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Satış</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Müşteri Adı (Customer Name) */}
                        <Form.Group controlId="formMusteriAdi">
                            <Form.Label>Müşteri Adı</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Müşteri Adı Girin"
                                value={customerName}
                                onChange={(e) => setCustomerName(e.target.value)}  // Assuming you have a customerName state
                            />
                        </Form.Group>

                        {/* Sevkiyat No (Shipment No) */}
                        <Form.Group controlId="formSiparişNo" className="mt-3">
                            <Form.Label>Sipariş No</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Sipariş No Girin"
                                value={shipmentNo}
                                onChange={(e) => setShipmentNo(e.target.value)}  // Assuming you have a shipmentNo state
                            />
                        </Form.Group>

                        {/* Sevk Tarihi (Shipment Date) */}
                        <Form.Group controlId="formSevkTarihi" className="mt-3">
                            <Form.Label>Sevk Tarihi</Form.Label>
                            <Form.Control
                                type="date"
                                value={shipmentDate}
                                onChange={(e) => setShipmentDate(e.target.value)}  // Assuming you have a shipmentDate state
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleCancel(setShowSellModal)} disabled={isCancelling}>
                        {isCancelling ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                            'İptal'
                        )}
                    </Button>
                    <Button variant="primary" onClick={handleSell} disabled={isSelling}>
                        {isSelling ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                            'Sat'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    );
};

export default Inventory;