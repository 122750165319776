import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Form, FormControl, Modal, Spinner, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FetchService } from '../services/FetchService';
import * as XLSX from 'xlsx';

interface SoldItem {
    sku: string;
    uretimTarihi: string;
    isim: string;
    durum: string;
    kategori: string;
    kalinlik: string;
    uzunluk: string;
    materyal: string;
    renk: string;
    kilogram: number;
    makineNo: string;
    urunEkleyen: string;
    musteriAdi: string;
    sevkNo: string;
    sevkTarih: string;
}

const fetchService = new FetchService("https://6pecywccp1.execute-api.eu-central-1.amazonaws.com");

const SoldItems: React.FC = () => {
    const [soldItems, setSoldItems] = useState<SoldItem[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [labelUrl, setLabelUrl] = useState<string | null>(null);
    const [showLabelModal, setShowLabelModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); 
    const itemsPerPage = 10; 

    // Ref to the label image for printing
    const labelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchItems = async () => {
            setIsLoading(true);
            try {
                const data = await fetchService.getItems('sold/items');

                const sortedData = data.sort((a: { sevkTarih: string | number | Date; }, b: { sevkTarih: string | number | Date; }) => new Date(b.sevkTarih).getTime() - new Date(a.sevkTarih).getTime());

                setSoldItems(sortedData);
            } catch (error) {
                console.error('Failed to fetch sold items:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchItems();
    }, []);

    // Function to retrieve the label and show the modal
    const handleRetrieveLabel = async (sku: string) => {
        try {
            const response = await fetchService.getItem(`labels/${sku}`);
            setLabelUrl(response.label_url); // Assuming the label URL is returned in the API response
            setShowLabelModal(true);
        } catch (error) {
            console.error('Failed to retrieve label:', error);
        }
    };

    // Print the label by opening the print window for the label image
    const handlePrint = () => {
        if (labelRef.current) {
            const printContents = labelRef.current.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload();
        }
    };

    const filteredItems = soldItems.filter(
        (item) =>
            item.isim.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.sku.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.musteriAdi.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const exportToExcel = () => {

        const reorderedItems = soldItems.map(item => ({
            "İsim": item.isim, 
            "Kategori": item.kategori, 
            "Kalınlık": item.kalinlik, 
            "Uzunluk": item.uzunluk, 
            "Kilogram": item.kilogram,
            "Renk": item.renk, 
            "Materyal": item.materyal, 
            "Ürünü Ekleyen": item.urunEkleyen, 
            "Üretim Tarihi": item.uretimTarihi, 
            "Müşteri Adı": item.musteriAdi, 
            "Sevkiyat No": item.sevkNo, 
            "Sevkiyat Tarihi": item.sevkTarih, 
            "SKU": item.sku, 
            "Durum": item.durum, 
            "Makine No": item.makineNo
        }));


        const worksheet = XLSX.utils.json_to_sheet(reorderedItems);
        
        const workbook = XLSX.utils.book_new();
    
        XLSX.utils.book_append_sheet(workbook, worksheet, "Satış Raporu");
    
        // gmt+3 for Turkey
        const date = new Date();
        date.setHours(date.getHours() + 3);
        XLSX.writeFile(workbook, `Satılanlar_Rapor_Molhalat_${date.toISOString()}.xlsx`);
    };

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    const paginatedItems = filteredItems.slice(
        (currentPage-1) * itemsPerPage,
        currentPage * itemsPerPage
    )

       // Pagination functions
       const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    //check later /////////////////////////////
    const goToPage = (page:any) => {
        setCurrentPage(page);
    };

    return (
        <div className="container mt-4">
            <h2>Satılan Ürünler</h2>

            <div className="mb-4">
                <Form className="d-flex">
                    <FormControl
                        type="search"
                        placeholder="Satılan Ürün Ara"
                        className="me-2"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Button variant="outline-success">Ara</Button>
                </Form>
            </div>

            <div className="d-flex justify-content-between mt-2 mb-2">
                <Button variant="success" size="lg" onClick={exportToExcel}>Rapor İndir</Button>
            </div>

            {isLoading ? (
                <div className="text-center my-4">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div className="table-responsive">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Ürün adı</th>
                                <th>SKU</th>
                                <th>Müşteri Adı</th>
                                <th>Sevkiyat No</th>
                                <th>Sevkiyat Tarihi</th>
                                <th>Eylemler</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedItems.map((item) => (
                                <tr key={item.sku}>
                                    <td>{item.isim}</td>
                                    <td>{item.sku}</td>
                                    <td>{item.musteriAdi}</td>
                                    <td>{item.sevkNo}</td>
                                    <td>{item.sevkTarih}</td>
                                    <td>
                                        <Button variant="primary" size="sm" className="me-2" onClick={() => handleRetrieveLabel(item.sku)}>
                                            Etiketi Görüntüle
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            {/* Pagination Buttons under table */}
            <div className="pagination-control d-flex justify-content-center my-3">
                {/* Önceki Sayfa Butonu */}
                <OverlayTrigger placement="top" overlay={<Tooltip>Önceki Sayfa</Tooltip>}>
                    <Button
                        variant="outline-primary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="mx-1"
                    >
                        {"<"}
                    </Button>
                </OverlayTrigger>

                {/* Page Number Buttons */}
                {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    if (
                        pageNumber === 1 ||
                        pageNumber === totalPages ||
                        (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2)
                    ) {
                        return (
                            <Button
                                variant={currentPage === pageNumber ? 'primary' : 'outline-secondary'}
                                key={pageNumber}
                                onClick={() => goToPage(pageNumber)}
                                className={`mx-1 ${currentPage === pageNumber ? 'fw-bold' : ''}`}
                            >
                                {pageNumber}
                            </Button>
                        );
                    } else if (
                        (pageNumber === currentPage - 3 && currentPage > 4) ||
                        (pageNumber === currentPage + 3 && currentPage < totalPages - 3)
                    ) {
                        return <span key={pageNumber} className="mx-2 text-muted">...</span>;
                    } else {
                        return null;
                    }
                })}

                {/* Sonraki Sayfa Butonu */}
                <OverlayTrigger placement="top" overlay={<Tooltip>Sonraki Sayfa</Tooltip>}>
                    <Button
                        variant="outline-primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="mx-1"
                    >
                        {">"}
                    </Button>
                </OverlayTrigger>
            </div>


            {/* Label Modal */}
            <Modal show={showLabelModal} onHide={() => setShowLabelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Etiket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div ref={labelRef}>
                        {labelUrl ? (
                            <Image src={labelUrl} fluid />
                        ) : (
                            <p>Etiket yüklenemedi.</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowLabelModal(false)}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={handlePrint}>
                        Yazdır
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SoldItems;
